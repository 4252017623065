








































































import { AuthorizedPickupPerson } from '@/models/authorizedPickupPerson';
import { ErrorManager } from '@/models/error';
import { FormRules } from '@/utils/formRules';
import { cloneDeep } from 'lodash';
import Vue from 'vue'
import { mapActions } from 'vuex';
export default Vue.extend({
    name: 'AuthorizedPickupPersonForm',
    mixins: [FormRules],
    data(){
        return{
            dialog: false,
            loading: false,
            enableEdit: false,
            valid:false,
            authorizedPickupPerson: new AuthorizedPickupPerson(),
            errorMessage: '',
            items: [{text:'Passport', value: 'PP'},
                    {text: "Driver's License", value: 'DL'}, 
                    {text: "Voter's ID", value:'VID'}]
        }
    },
    computed: {
      errorOccurred(): boolean {
        return this.errorMessage.length > 0;
      }
    },
    methods: {
      ...mapActions({
        createAuthorizedPickupPerson: 'authorizedPickupPerson/createAuthorizedPickupPerson',
        updateAuthorizedPickupPerson: 'authorizedPickupPerson/updateAuthorizedPickupPerson'
      }),

        open(authorizedPickupPerson: AuthorizedPickupPerson, edit = false){
          this.enableEdit = edit;
          this.dialog = true;
          if (this.enableEdit){
            this.authorizedPickupPerson = cloneDeep(authorizedPickupPerson);
          }
        },

        close(){
          this.dialog = false;
          (this.$refs.form as any).reset();
          this.authorizedPickupPerson = new AuthorizedPickupPerson();
        },

        saveAuthorizedPickupPerson(){
          this.loading = true;
          this.createAuthorizedPickupPerson(this.authorizedPickupPerson).then((data) => {
            this.close();
          }).catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          }).finally(()=> this.loading = false);
        },

        editAuthorizedPickupPerson(){
          this.loading = true;
          this.updateAuthorizedPickupPerson(this.authorizedPickupPerson).then((data) => {
            this.close();
          }).catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          }).finally(()=> this.loading = false);
        }

    },
})
