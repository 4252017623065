
































































































































































import { ErrorManager } from '@/models/error';
import Vue from 'vue'
import { mapActions, mapState } from 'vuex';
import { AuthorizedPickupPerson } from '@/models/authorizedPickupPerson'
import AuthorizedPickupPersonForm from './AuthorizedPickupPersonForm.vue';
export default Vue.extend({
    name: 'Authorized-Pickup-Person',
    components: {
        AuthorizedPickupPersonForm
    },
    data(){
        return{
        search: '',
        errorMessage: '',
        loading:false,
        selectedAuthorizedPickupPerson: new AuthorizedPickupPerson(),
        deleteAuthorizedPickupPersonDialog: false
        }
    },
    computed: {
        ...mapState('authorizedPickupPerson', ['authorizedPickupPerson']),

        userInfo(){   
            return JSON.parse(localStorage.getItem('user')!);
        },

        filteredNewAuthorizedPickupUser(): AuthorizedPickupPerson[]{
           const userId = this.userInfo == null ? null : this.userInfo.pk;
           return this.authorizedPickupPerson.filter((model: AuthorizedPickupPerson) => model.user == userId);
        },

        errorOccurred(): boolean {
            return this.errorMessage.length > 0;
        }
    },
     methods: {
        ...mapActions({
            fetchAuthorizedPickupPersons: 'authorizedPickupPerson/fetchAuthorizedPickupPersons',
            deleteAuthorizedPickupPerson: 'authorizedPickupPerson/deleteAuthorizedPickupPerson'
        }),

        openDialog(authorizedPickupPerson : AuthorizedPickupPerson, editForm = false){
           if (editForm){
                (this as any).$refs.authorizedpickuppersonform.open(authorizedPickupPerson,editForm) 
            } else {
                (this as any).$refs.authorizedpickuppersonform.open();
            }
        },

        selectAuthorizedPickupPerson(authorizedPickupPerson : AuthorizedPickupPerson){
            this.deleteAuthorizedPickupPersonDialog = true;
            this.selectedAuthorizedPickupPerson = authorizedPickupPerson;
        },

        removeAuthorizedPickupPerson(){
            this.loading = true;
            this.deleteAuthorizedPickupPerson(this.selectedAuthorizedPickupPerson).then(()=> {
                this.fetchAuthorizedPickupPersons().then(()=> {
                    this.deleteAuthorizedPickupPersonDialog = false;

                }).catch((error: any) => {
                    this.errorMessage = ErrorManager.extractApiError(error);
                });
                
            }).catch((error: any) => {
                this.errorMessage = ErrorManager.extractApiError(error);
            })
            .finally(()=> {
                this.loading = false
            });
        },
       
        filterAuthorizedPichupPersonObject(queryText: string){
            const filter = this.filteredNewAuthorizedPickupUser.filter((element: AuthorizedPickupPerson) => {
              return ( element.first_name!.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || 
              element.last_name!.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1);
              });
            return filter;
         },

    },

    mounted(){
        this.loading = true;
         this.fetchAuthorizedPickupPersons().catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
        }).finally(() => {this.loading = false});
    }
})
