






import Vue from 'vue';
import AuthorizedPickupPerson from '../../components/authorizedpickupperson/AuthorizedPickupPerson.vue';
export default Vue.extend({
    name: 'AuthorizedPickupPersonPage',
    components: {
        AuthorizedPickupPerson
    },
    metaInfo(){
      return  {
      title: "Authorized Pickup Person - Our Nation Express",
     
    };
    }
})
