






import Vue from 'vue'
import AuthorizedPickupPersonPage from './AuthorizedPickupPerson.vue'
export default Vue.extend({
    name: 'AuthorizedPickupPersonIndex',
    components: {
        AuthorizedPickupPersonPage
    },
})
